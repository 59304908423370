<template>
    <div 
        class="gstc-wrapper max-h-timeline-container app-calendar position-relative overflow-hidden text-sm" 
        ref="gstc"
    ></div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, onUnmounted, watch } from '@vue/composition-api'
import GSTC from 'gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js'
import { Plugin as CalendarScroll } from 'gantt-schedule-timeline-calendar/dist/plugins/calendar-scroll.esm.min.js';
import { Plugin as HighlightWeekends } from 'gantt-schedule-timeline-calendar/dist/plugins/highlight-weekends.esm.min.js';
import { Plugin as ProgressBar } from 'gantt-schedule-timeline-calendar/dist/plugins/progress-bar.esm.min.js';
import { Plugin as ItemTypes } from 'gantt-schedule-timeline-calendar/dist/plugins/item-types.esm.min.js';
import "gantt-schedule-timeline-calendar/dist/style.css";
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';

import { avatarText } from '@core/utils/filter'
import { stringToHslColor, rgbToHex } from '@core/utils'
import useTimeline from './useTimeline'

export default ({
    props: {
        timelineEvents: {
            type:Object,
            required: true
        },
        filterUserTasks: {
            type: Boolean,
            required: true
        }
    },
    setup(props, {emit}) {
        const vm = getCurrentInstance().proxy

        const {
            months,
            weeks,
            days,
            gstc,
            state,
            startDate,
            endDate,
            columns,
            activeTimelineView,
            activeSortView,


            convertGSTCObjToTaskObj,

        } = useTimeline()

        const GSTCID = GSTC.api.GSTCID;

        

        const startTime = ref({})
        const endTime = ref({})

        startTime.value = startDate.value.valueOf()
        endTime.value = endDate.value.valueOf()



        const rows = ref(JSON.parse(JSON.stringify(props.timelineEvents.rows)))
        const items = ref(props.timelineEvents.items)

        const setTippyContent = (element, data) => {
            if (!gstc || Object.keys(gstc.value).length === 0) return;
            if ((!data || !data.item) && element._tippy) return element._tippy.destroy();
            const itemData = gstc.value.api.getItemData(data.item.id);
            if (!itemData) return;
            if (!itemData && element._tippy) return element._tippy.destroy();
            if (itemData.detached && element._tippy) return element._tippy.destroy();
            // @ts-ignore
            if (!itemData.detached && !element._tippy) tippy(element, { trigger: 'mouseenter', theme: 'secondary' });
            if (!element._tippy) return;
            const tooltipContent = `${data.item.label}`;
            element._tippy.setContent(tooltipContent);
        }
        
        const itemTippy = (element, data) => {
            setTippyContent(element, data);
            const onClick = () => {
                element._tippy.destroy()
                if (!data.row.disabled) onTaskCellClick(data)
            }

            if (element && data.item){
                element.addEventListener('click', onClick)
            }

            return {
                update(element, data) {
                if (element._tippy) element._tippy.destroy();
                    setTippyContent(element, data);
                },
                destroy(element, data) {
                    if (element._tippy) element._tippy.destroy();
                    element.removeEventListener('click', onClick);
                },
            };
        }
        
        const itemSlot = (vido, props) => {
            const { html, onChange, update } = vido;
            let imageSrc = '';
            let description = '';
            onChange((newProps) => {
                props = newProps;
                if (!props || !props.item) return;
                imageSrc = props.item.img;
                description = props.item.description;
                update();
            });

            return (content) =>
                html`
                <div class="item-text">
                    <div class="item-label">${content}</div>
                    <div class="item-description" style="font-size:11px;margin-top:2px;color:#fffffff0;line-height:1em;">
                    ${description}
                    </div>
                </div>`;
        }
        
        const rowSlot = (vido, props) => {
            const { html, onChange, update, api } = vido;

            let color = '';
            let avatar = '';

            const openProjectDetailDialog = (rowDetails) => {
                if(rowDetails.job_number){
                    emit('click:project-detail', rowDetails)
                }
            }

            onChange((newProps) => {
                props = newProps;
                if (!props || !props.row) return;
                avatar = props.row.avatar;
                color = props.row.color;
                update();
            });

            return (content) => {
                if (!props || !props.column) return content;
                if (api.sourceID(props.column.id) === 'label') {
                    if (props.row.class === 'user'){
                        return html`<div class="row-content-wrapper" style="display:flex;">
                            <div class="row-content" style="flex-grow:1;">
                                <div 
                                    class="v-avatar"
                                    style="height: 30px; min-width: 30px; width: 30px; background-color: ${color}; border-color: ${color};vertical-align: middle !important;"
                                >
                                    <span class="white--text text-xs">${avatar}</span>
                                </div>
                            
                            ${content} ${props.row.supplier ? ' - '+props.row.supplier : ''}
                            </div>
                        </div>`
                    } else {
                        return html`<div class="row-content-wrapper" style="display:flex;cursor:pointer;" @click=${() =>
                        openProjectDetailDialog(props.row)}>
                            
                            ${content}
                            </div>
                        </div>`
                    }
                } else {
                    return content;
                }
            };
        }

        const onTaskCellClick = (data) => {
            const item = {
                ...data.item,
                class: data.row.class,
                calendar: {
                    start: startTime.value
                }
            }
            emit('click:task', convertGSTCObjToTaskObj(item))
        }

        const onEmptyGridCellClick = (item, time) => {
            const parentId = item['parentId'] ? item['parentId'] : item.id
            const assignments = item.azure_ad_id ? [item.azure_ad_id] : []
            item = {
                time: {
                    start: time.leftGlobal,
                    end: time.rightGlobal,
                },
                project: item.project,
                assignments,
                class: item.class,
                calendar: {
                    start: startTime.value
                }
            }
            emit('click:day', convertGSTCObjToTaskObj(item))
        }

        
        const onEmptyCell = ({ row, time, vido }) => {
            return vido.html`<div class="gstc__chart-timeline-grid-row-cell" @click=${() => {
                if (row.disabled) return
                return onEmptyGridCellClick(row, time)}}></div>`;
        }

        const today = (timelineView) => {
            startDate.value = GSTC.api.date()
            updateTimeline(timelineView, true)
        }

        const next = (timelineView) => {
            startDate.value = startDate.value.add(1,timelineView.value.value)
            updateTimeline(timelineView)
        }

        const prev = (timelineView) => {
            startDate.value = startDate.value.add(-1,timelineView.value.value)
            updateTimeline(timelineView)
        }

        const updateSort = (activeSortView) => {
            state.value.update('config.list.sort', (sort) => {
                sort.activeColumnId = GSTC.api.GSTCID(activeSortView.value.option);
                sort.asc = activeSortView.value.asc;
                return sort;
            });
        }

        const filterItems = (check) => {
            state.value.update('config.chart.items', items => {
                items = JSON.parse(JSON.stringify(props.timelineEvents.items))
                if (check){
                    const filteredItems = {}
                    for(const itemId in items){
                        const item = items[itemId];
                        if (item.owner && item.assignments.includes(item.owner)) filteredItems[itemId] = item;
                    } 
                    items = filteredItems
                }
                return items
            })
            // if (check) {
            //     state.value.update('config',config=>{
            //         // for(const rowId in config.list.rows){
            //         //     const row = config.list.rows[rowId];
            //         //     filteredRows[rowId] = row;
            //         // }
            //         // clean up items
            //         const filteredItems = {};

            //         for(const itemId in config.chart.items){
            //             const item = config.chart.items[itemId];
            //             if (item.owner && item.assignments.includes(item.owner)) filteredItems[itemId] = item;
            //         }
            //         config.chart.items = filteredItems;
            //         return config;
            //     });
            // } else {
            //     state.value.update('config',config=>{
            //         config.chart.items = props.timelineEvents.items;
            //         return config;
                    
            //     })
            // }

        }

        const updateTimeline = (timelineView, today=false) => {

            //Month start at 0 so need +1 to account for 0 = Jan
            if (today){
                startTime.value = startDate.value
                    .startOf('day')
                    .valueOf();
            } else {
                startTime.value = startDate.value
                    .startOf(timelineView.value.value)
                    .valueOf();
            }
            endTime.value = startDate.value.clone()
                .add(timelineView.value.jump, timelineView.value.value)
                .endOf(timelineView.value.value)
                .valueOf();

            emit('click:event', startTime.value.valueOf(), endTime.value.valueOf())
            
            state.value.update('config.chart.time', (time) => {
                time.from = startTime.value
                time.to = endTime.value
                time.calculatedZoomMode = true;
                return time;
            });
            
        }
        
        onMounted(  () => {

            
            const config = {
                licenseKey:process.env.VUE_APP_GSTC_KEY,
                plugins: [
                    HighlightWeekends(),
                    CalendarScroll(),
                    ProgressBar(),
                    ItemTypes(),
                ],
                list: {
                    row: {
                        height: 40,
                    },
                    rows,
                    columns,
                    toggle: {display: false},
                },
                chart: {
                    time: {
                        calculatedZoomMode: true,
                        from: startDate.value.startOf(activeTimelineView.value.value).valueOf(),
                        to: startDate.value.add(activeTimelineView.value.jump, activeTimelineView.value.value).endOf(activeTimelineView.value.value).valueOf(),
                    },
                    item: {
                        height: 30,
                        gap: {
                            top: 2,
                            bottom: 2,
                        },
                    },
                    items: items,
                    calendarLevels: [
                        months,
                        weeks,
                        days
                    ],
                    grid: {
                        cell: {
                            onCreate: [onEmptyCell],
                        },
                    },
                },
                scroll: {
                    vertical: { precise: true },
                    horizontal: { precise: true },
                },
                actions: {
                    'chart-timeline-items-row-item': [itemTippy],
                },
                slots: {
                    'chart-timeline-items-row-item': { content: [itemSlot] },
                    'list-column-row': { content: [rowSlot] },
                },
                autoInnerHeight: true,
            };
                        
            state.value = GSTC.api.stateFromConfig(config);
            gstc.value = GSTC({
                element: vm.$refs['gstc'],
                state: state.value,
            });

            updateSort(activeSortView)
        })

        onUnmounted(() => {
            if (gstc) gstc.value.destroy();
        })

        watch(
            () => props.timelineEvents,
            () => {
                items.value = {}
                rows.value = JSON.parse(JSON.stringify(props.timelineEvents.rows))
                items.value = props.timelineEvents.items
                
                state.value.update(`config.chart.items`, items.value);
                filterItems(props.filterUserTasks)
                updateSort(activeSortView)
            }
        )
        return {
            next,
            prev,
            today,
            startTime,
            endTime,
            updateTimeline,
            updateSort,
            filterItems
        }   
        
    },
    })
</script>

<style lang="scss">
    .v-main {
        // If AppBar & Footer is present
        &[style='padding: 64px 0px 56px 260px;'],
        &[style='padding: 64px 0px 56px 68px;'], // collapsed
        &[style='padding: 64px 260px 56px 0px;'], // RTL
        &[style='padding: 64px 68px 56px 0px;'], // RTL Collapsed
        &[style='padding: 64px 0px 56px;'] {
        .max-h-timeline-container {
            height: calc((var(--vh, 1vh) * 100) - 64px - 56px - (1.5rem * 2) - 150px);
        }
        }
    }
    .gstc__chart-timeline-grid-row-cell--weekend {
        background: rgba(142, 195, 252, 0.5);
    }
    .gstc__chart-timeline-grid-row-cell.current{
        background: rgba(15, 241, 109, 0.4);
    }

</style>
