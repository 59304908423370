<template>
  <!-- edit profile dialog -->
  <v-dialog
      v-model="isProjectDialogOpen"
      max-width="800px"
      @click:outside="$emit('update:is-project-dialog-open',false)"
    >
    <v-card 
      :style="`border: 2px solid ${projectDataLocal.color} !important;`"
    >
      <v-row class="ma-0 h-full">
        <v-col
          cols="12"
          sm="8"
          md="12"
          lg="8"
          order="2"
          order-lg="1"
        >
          <v-card-title class="text-xl font-weight-bold text-uppercase">{{projectDataLocal.job_number}}{{projectDataLocal.ges_bva?`/${projectDataLocal.ges_bva}`:''}} - {{projectDataLocal.job_name}} - Project Detail</v-card-title>
          <v-card-text class="d-flex align-center flex-wrap body-1">
            <span class="text-sm" v-if="projectDataLocal.category">{{projectDataLocal.category.name}} | </span>
            <v-chip
              small
              label
              :color="resolveProjectStatusVariant(projectDataLocal.status.name)"
              :class="`v-chip-light-bg ${resolveProjectStatusVariant(projectDataLocal.status.name)}--text font-weight-medium text-capitalize ml-2 flex-shrink-0`"
              v-if="projectDataLocal.status"
            >
              {{ projectDataLocal.status.name }}
            </v-chip>
          </v-card-text>
          <v-card-text>
            This project is being managed by 
            <strong>
              {{projectDataLocal.contact}}
            </strong> and is fielded in <strong>{{projectDataLocal.field_countries}}</strong>.
            The total sample size is <strong>N={{projectDataLocal.sample}}</strong>.
            <template v-if="projectDataLocal.qual_sample_types">The Qual sample type{{projectDataLocal.qual_sample_types.length > 1 ? 's': ''}} for this study {{projectDataLocal.qual_sample_types.length > 1 ? 'are': 'is'}} 
              <strong>{{projectDataLocal.qual_sample_types}}</strong>.
            </template>
            <br>
            Report is due on <u>{{projectDataLocal.report_date}}</u>.
          </v-card-text>
          <v-card-text>
            <v-divider></v-divider>
          </v-card-text>
          <v-card-actions class="d-flex justify-center text-sm">
            <div class="me-auto">
              <p class="d-flex align-center mb-6">
                <v-icon color="primary">
                  {{ icons.mdiClipboardCheckMultipleOutline }}
                </v-icon>
                <span class="ms-3" v-if="projectDataLocal.project_type">{{projectDataLocal.project_type.name}}</span>
              </p>
              <p class="d-flex align-center mb-0">
                <v-icon color="primary">
                  {{ icons.mdiHomeOutline }}
                </v-icon>
                <span class="ms-3" v-if="projectDataLocal.business_initiative">{{projectDataLocal.business_initiative.name}}</span>
              </p>
            </div>

            <v-divider
              v-if="$vuetify.breakpoint.smAndUp"
              vertical
              inset
            >
            </v-divider>

            <div class="ms-auto ps-4">
              <p class="d-flex align-center mb-6">
                <v-icon color="primary">
                  {{ icons.mdiImageMultiple }}
                </v-icon>
                <span class="ms-3">{{projectDataLocal.research_method}}</span>
              </p>
              <p class="d-flex align-center mb-0">
                <v-icon color="primary">
                  {{ icons.mdiStarOutline }}
                </v-icon>
                <span class="ms-3" v-if="projectDataLocal.service_type">{{projectDataLocal.service_type.name}}</span>
              </p>
            </div>
            
          </v-card-actions>
          <v-card-actions v-if="projectDataLocal.modules">
            
            <div class="me-auto pe-4">
              <p class="d-flex align-center mb-0 text-sm">
                <v-icon color="primary">
                  {{ icons.mdiFeatureSearchOutline }}
                </v-icon>
                <span class="ms-3">{{projectDataLocal.modules.join(', ')}}</span>
              </p>
            </div>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions v-if="projectDataLocal.key_comments">
            
            <div class="me-auto pe-4">
              <p class="d-flex align-center mb-0 text-sm">
                <v-icon color="primary">
                  {{ icons.mdiCommentTextOutline }}
                </v-icon>
                <span class="ms-3">{{projectDataLocal.key_comments}}</span>
              </p>
            </div>
          </v-card-actions>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="12"
          lg="4"
          order="1"
          order-lg="2"
          class="projectpricing-bg"
          v-if="projectDataLocal.status"
        >
          
          <v-card-actions class="dense d-flex justify-end">
            <v-tooltip 
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                  icon
                  @click="openProjectDetail"
                  >
                      <v-icon
                        color="warning"
                        size="22"
                      >
                        {{ icons.mdiFileDocumentOutline }}
                      </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Project Page</span>
            </v-tooltip>
            <v-tooltip 
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                  icon
                  @click="openTimeline"
                  >
                      <v-icon
                        size="22"
                      >
                        {{ icons.mdiChartTimeline }}
                      </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Timeline Page</span>
            </v-tooltip>
            <v-tooltip 
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-btn
                  icon
                  @click="openSharepoint"
                  >
                      <v-icon
                        color="primary"
                        size="22"
                      >
                        {{ icons.mdiMicrosoftSharepoint }}
                      </v-icon>
                  </v-btn>
                </div>
              </template>
              <span>Sharepoint Page</span>
            </v-tooltip>
            
          </v-card-actions>
          <div class="project-pricing">
            <p class="mt-16 text--primary">
                <span :class="`text-5xl font-weight-semibold ${resolveProjectStatusVariant(projectDataLocal.status.name)}--text`"
                v-if="projectDataLocal.budget">{{ projectDataLocal.budget.toLocaleString('fr-FR') }}</span>
                <sup :class="`${resolveProjectStatusVariant(projectDataLocal.status.name)}--text`">{{projectDataLocal.currency}}</sup>
            </p>
            <div class="d-flex flex-column">
              <span class="text-sm" v-if="projectDataLocal.client">
                {{projectDataLocal.client.name}}</span>
              <span class="text-sm"> {{projectDataLocal.brand}}</span>
            </div>
          </div>
          
          <v-divider></v-divider>
          <section class="avatars-group grid pa-3">
            <avatar
              :userList="projectDataLocal.team_members"
              display="grid pa-3"
              label="Team"
            >
            </avatar>
              
          </section>
        </v-col>
      </v-row>
      <v-divider class="mt-0"></v-divider>
      <v-card-text
        v-if="projectDataLocal.editable"
      >
          <v-row
          justify="space-between"
        >
          <v-col class="text-left">
            <span class="subheading font-weight-light">Moved all tasks by </span>
            <span
              class="font-weight-light"
              v-text="daysShifted"
            ></span>
            <span class="subheading font-weight-light me-1"> days</span>
          </v-col>
          <v-col class="text-right">
            <v-btn
              :color="color"
              dark
              depressed
              fab
              small
              @click="updateTasks"
              :loading="isUpdating"
            >
              <v-icon 
                size="22">
                {{ icons.mdiUpdate }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-slider
          v-model="daysShifted"
          :color="color"
          track-color="grey"
          always-dirty
          min="0"
          max="100"
          hide-details
        >
          <template #prepend>
            <v-icon
              :color="color"
              @click="decrement"
            >
              {{ icons.mdiMinus }}
            </v-icon>
          </template>

          <template #append>
            <v-icon
              :color="color"
              @click="increment"
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </template>
        </v-slider>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import router from '@/router'
import { mdiClipboardCheckMultipleOutline,
          mdiHomeOutline,
          mdiStarOutline,
          mdiImageMultiple,
          mdiCommentTextOutline,
          mdiFeatureSearchOutline,
          mdiFileDocumentOutline,
          mdiChartTimeline,
          mdiMicrosoftSharepoint,
          mdiMinus, mdiPlus,
          mdiUpdate,
        } from '@mdi/js'
import {
  required,
} from '@core/utils/validation'
import { addDays } from '@core/utils'
import useProjectsList from '@/views/apps/project/project-list/useProjectsList'
import Avatar from '@/views/components/avatar/Avatar.vue'

export default {
  components: {
    Avatar
  },
  props: {
    isProjectDialogOpen: {
      type: Boolean,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {

    const projectDataLocal = ref({})
    const daysShifted = ref(0)
    const isUpdating = ref(false)

    const {
      resolveProjectStatusVariant
    } = useProjectsList()

    const openSharepoint = () => {
      window.open(projectDataLocal.value.folder_web_url, '_blank')
    }

    const openTimeline = () => {
      const route = router.resolve({
         path: `/timeline/project/${projectDataLocal.value.job_number}` 
      })
      window.open(route.href, '_blank')
    }

    const openProjectDetail = () => {
      const route = router.resolve({
         path: `/projects/${projectDataLocal.value.job_number}` 
      })
      window.open(route.href, '_blank')
    }

    const color = computed(() => {
      if (daysShifted.value < 7) return 'success'
      if (daysShifted.value < 21) return 'primary'
      if (daysShifted.value < 50) return 'info'
      if (daysShifted.value < 75) return 'warning'

      return 'error'
    })

    const decrement = () => {
      daysShifted.value -= 1
    }

    const increment = () => {
      daysShifted.value += 1
    }

    const updateTasks = () => {
      isUpdating.value = true
      
      for(const task of projectDataLocal.value.tasks){
        task.due_date_time = addDays(task.end, daysShifted.value)
        task.start_date_time = addDays(task.start, daysShifted.value)
        task.start = addDays(task.start, daysShifted.value)
        task.end = addDays(task.end, daysShifted.value)
        emit('update-task', task)
      }
      isUpdating.value = false

    }
    
    watch(
      () => props.isProjectDialogOpen,
      () => {
        projectDataLocal.value = JSON.parse(JSON.stringify(props.projectData))
        daysShifted.value = 0
      }
    )

    return {
      projectDataLocal,
      resolveProjectStatusVariant,
      openSharepoint,
      openProjectDetail,
      openTimeline,
      daysShifted,
      color,
      decrement,
      increment,
      isUpdating,
      updateTasks,
      icons: { 
        mdiClipboardCheckMultipleOutline,
        mdiHomeOutline,
        mdiStarOutline,
        mdiImageMultiple,
        mdiCommentTextOutline,
        mdiFeatureSearchOutline,
        mdiFileDocumentOutline,
        mdiChartTimeline,
        mdiMicrosoftSharepoint,
        mdiMinus, mdiPlus, mdiUpdate,
      },
      // Field Validators
      validators: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(projectpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.projectpricing-bg {
  position: relative;
}
.project-pricing {
  text-align: center;
}
</style>

