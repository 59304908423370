<template>
  <div class="h-full">
    <alert />
    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">{{ taskLocal.id ? 'Update' : 'Add' }} Task {{taskLocal.project ? ` - ${taskLocal.project}` : ''}}</span>
      <v-spacer></v-spacer>
      <v-btn
        v-if="taskLocal.id && !TaskNameDisabled"
        icon
        small
        class="me-1"
        :loading="loadingDel"
        @click=" $emit('remove-task', taskLocal); loadingDel=true"
      >
        <v-icon size="22">
          {{ icons.mdiTrashCanOutline }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        small
        @click="$emit('close-drawer')"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
    <perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-timeline-task-handler"
    >
      <v-form
        ref="refCalendarEventHandlerForm"
        class="px-5 pt-3 pb-10"
        @submit.prevent="handleFormSubmit"
      >
      <template
          v-if="taskLocal.project">
        <v-combobox
          v-model="taskLocal.task"
          :items="taskOptions"
          :search-input.sync="taskSearch"
          :loading="taskLoading"
          hide-selected
          small-chips
          deletable-chips
          item-text="name"
          item-value="name"
          label="Task"
          dense
          outlined
          :disabled="TaskNameDisabled"
          :rules="[validators.required, validators.forbiddenWords]"
          hide-details="auto"
          return-object
          @change="updateColor"
          class="mb-6"
        >
          <template v-slot:no-data>
            <div  class="d-flex align-center">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    No results matching "<strong>{{ taskSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
        </v-combobox>
        <v-select
            v-model="taskColor"
            outlined
            label="Task Color"
            placeholder="Task Color"
            :item-text="item => item.name"
            :item-value="item => item.color"
            :items="$store.state['app-timeline'].taskColorOptions"
            :rules="[validators.required]"
            hide-details="auto"
            :menu-props="{ offsetY: true }"
            dense
            class="mb-6"
          >
            <!-- Selected Item Slot -->
            <template #selection="{ item }">
              <div class="d-flex align-center">
                <v-badge
                  inline
                  :color="item.color"
                  dot
                  class="me-2"
                ></v-badge>
                <span>{{ item.name }}</span>
              </div>
            </template>

            <!-- Options Slot -->
            <template #item="{ item }">
              <div class="d-flex align-center">
                <v-badge
                  inline
                  :color="item.color"
                  dot
                  class="me-2 mb-1"
                ></v-badge>
                <span>{{ item.name }}</span>
              </div>
            </template>
          </v-select>
          </template>

        <template
          v-else>
          
          <v-text-field
            v-model="taskLocal.name"
            label="Label"
            placeholder="Label"
            outlined
            :rules="[validators.required]"
            hide-details="auto"
            dense
            class="mb-6"
          ></v-text-field>

          <v-select
            v-model="taskLocal.bucket"
            outlined
            label="Bucket"
            placeholder="Bucket"
            :item-text="item => item.name"
            :item-value="item => item.id"
            :items="$store.state['app-timeline'].calendarOptions"
            :rules="[validators.required]"
            hide-details="auto"
            :menu-props="{ offsetY: true }"
            dense
            class="mb-6"
          >
            <!-- Selected Item Slot -->
            <template #selection="{ item }">
              <div class="d-flex align-center">
                <v-badge
                  inline
                  :color="item.color"
                  dot
                  class="me-2"
                ></v-badge>
                <span>{{ item.name }}</span>
              </div>
            </template>

            <!-- Options Slot -->
            <template #item="{ item }">
              <div class="d-flex align-center">
                <v-badge
                  inline
                  :color="item.color"
                  dot
                  class="me-2 mb-1"
                ></v-badge>
                <span>{{ item.name }}</span>
              </div>
            </template>
          </v-select>
        </template>
        <v-menu
          v-model="isStartDateMenuOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          eager
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="taskLocal.start"
              label="Start Date"
              readonly
              outlined
              dense
              class="mb-6"
              hide-details="auto"
              v-bind="attrs"
              :rules="[validators.required]"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="taskLocal.start"
            @input="isStartDateMenuOpen = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="isEndDateMenuOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="taskLocal.end"
              label="Due Date"
              readonly
              outlined
              dense
              class="mb-6"
              hide-details="auto"
              v-bind="attrs"
              :rules="[validators.required, validators.endDateValidator]"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="taskLocal.end"
            @input="isEndDateMenuOpen = false"
          ></v-date-picker>
        </v-menu>

        <v-autocomplete
          v-model="taskLocal.assignments"
          outlined
          chips
          small-chips
          deletable-chips
          multiple
          dense
          class="mb-6 select-guest"
          hide-details="auto"
          label="Assignments"
          placeholder="Assignments"
          :menu-props="{ offsetY: true, contentClass: 'list-style' }"
          :item-text="user => user.name"
          :item-value="user => user.azure_ad_id"
          :items="userOptions"
          :loading="userLoading"
          :search-input.sync="userSearch"
          @change="userSearch=''"
              :rules="[validators.requiredArray]"
        >
          <!-- Options Slot -->
          <template #item="{ item }">
            <div class="d-flex align-center">
              <v-avatar
                    :color="stringToHslColor(item.name) || primary"
                    size="25"
                    class="me-2"
                >
                    <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                </v-avatar>
              <span class="text-sm">{{ item.name }}</span>
            </div>
          </template>
        </v-autocomplete>
        
        <v-slider
            v-model="taskLocal.percent_complete"
            :color="taskLocal.fill"
            label="progress"
            step="10"
            thumb-label
            ticks
        >
        </v-slider>
        <!-- <v-text-field
          v-model="taskLocal.extendedProps.location"
          label="Location"
          dense
          class="mb-6"
          hide-details="auto"
          placeholder="Location"
          outlined
        ></v-text-field> -->

        

        <v-textarea
          v-model="taskLocal.desc"
          outlined
          label="Description"
          placeholder="Description"
        ></v-textarea>

        <v-btn
          color="primary"
          class="me-3"
          type="submit"
        >
          {{ taskLocal.id ? 'Update' : 'Add ' }}
        </v-btn>
        <v-btn
          outlined
          @click="resetEventLocal"
        >
          Reset
        </v-btn>
      </v-form>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { ref, watch, getCurrentInstance, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

import { required, requiredArray, urlValidator } from '@core/utils/validation'
import { dateInPast } from '@core/utils'
import { mdiTrashCanOutline, mdiClose } from '@mdi/js'
import { foundryRequest } from '@/config/authConfig';
import { stringToHslColor } from '@core/utils'
import { avatarText } from '@core/utils/filter'
import Alert from '@/views/components/alert/Alert.vue'
import GSTC from 'gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js'

export default {
  components: {
    Alert,
    PerfectScrollbar,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    taskOptions: {
      type: Array,
      required: true
    },
    taskLoading: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    // ————————————————————————————————————
    //* ——— Template Refs
    // ————————————————————————————————————

    const refCalendarEventHandlerForm = ref(null)

    const loadingDel = ref(false)
    const taskSearch = ref('')
    const taskColor = ref('')

    // ————————————————————————————————————
    //* ——— Local Event
    // ————————————————————————————————————

    const taskLocal = ref(JSON.parse(JSON.stringify(props.task)))
    const resetEventLocal = () => {
      
      loadingDel.value = false

      taskLocal.value = JSON.parse(JSON.stringify(props.task))
      taskColor.value = taskLocal.value.color
      if (taskLocal.value.project) taskLocal.value.task = taskLocal.value.name
      // Only get date from task
      if (taskLocal.value.start) taskLocal.value.start = GSTC.api.date(taskLocal.value.start).format('YYYY-MM-DD')
      if (taskLocal.value.end) taskLocal.value.end = GSTC.api.date(taskLocal.value.end).format('YYYY-MM-DD')
      
      // Reset Validation
      refCalendarEventHandlerForm.value.resetValidation()
    }

    watch(
      () => props.task,
      () => {
        resetEventLocal()
      },
      { deep: true },
    )



    // ————————————————————————————————————
    //* ——— Form
    // ————————————————————————————————————

    const isStartDateMenuOpen = ref(false)
    const isEndDateMenuOpen = ref(false)

    const userLoading = ref(false)
    const userOptions = ref([])
    const userSearch = ref('')

    const vm = getCurrentInstance().proxy
    
    const fetchUsers = async () => {
        userLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-timeline/fetchUsers', {
        })
        .then(response => {
            const { data } = response
            userOptions.value = data
            // remove loading state
            userLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const updateColor = (event) => {
      taskColor.value = event.color
    }

    const handleFormSubmit = () => {
      const isFormValid = refCalendarEventHandlerForm.value.validate()

      if (!isFormValid) return

      const taskData = JSON.parse(JSON.stringify(taskLocal.value))
      
      // Only get date from event
      /* eslint-disable no-param-reassign */
      if (taskData.start) taskData.start_date_time = new Date(taskData.start).toISOString()
      if (taskData.end) taskData.due_date_time = new Date(taskData.end).toISOString()
      
      if (typeof taskData.task === 'object') {
        taskData.bucket = taskData.task.bucket
        taskData.name = taskData.task.name
        taskData.color =  taskColor.value
        delete taskData.task
      } else if (taskData.project) {
        taskData.name = taskData.task
        taskData.bucket = ''
        taskData.color = taskColor.value
        delete taskData.task
      }
      
      
      // * If task has id => Edit Task
      // Emit task for add/update task
      if (taskData.id) emit('update-task', taskData)
      else emit('add-task', taskData)

    }

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }

    // ————————————————————————————————————
    //* ——— Form Validator
    // ————————————————————————————————————

    const endDateValidator = val => {
      if (!taskLocal.value.start) return true

      return !dateInPast(new Date(val), new Date(taskLocal.value.start)) || 'End date is in past'
    }

    const forbiddenWords = value => {
        if (typeof value === 'string' && !TaskNameDisabled){
            return (value && !value.includes('Field Work') && !value.includes('Report')) || '"Field Work" and "Report" are blocked words'
        }
        return true
    }

    const TaskNameDisabled = computed(() => {
      if (taskLocal.value.task && (taskLocal.value.task.includes('Field Work') || taskLocal.value.task.includes('Report'))) return true
      return false
    })

    onMounted( () => {
      fetchUsers()
    })

    return {
      // Template Refs
      refCalendarEventHandlerForm,
      taskColor,
      updateColor,

      // Local Event
      taskLocal,
      resetEventLocal,

      // Form
      isStartDateMenuOpen,
      isEndDateMenuOpen,
      handleFormSubmit,
      userLoading,
      userOptions,
      userSearch,
      loadingDel,
      taskSearch,
      TaskNameDisabled,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Field Validators
      validators: {
        required,
        requiredArray,
        urlValidator,
        endDateValidator,
        forbiddenWords
      },

      // Icons
      icons: {
        mdiTrashCanOutline,
        mdiClose,
      },

      stringToHslColor,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
.ps-timeline-task-handler {
  height: calc(100% - 44px - 24px - 2px);

  // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
  // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
  .select-guest {
    .v-select__selections {
      .v-chip--select {
        margin-bottom: 6px;
        margin-top: 6px;
      }
    }
  }
}
</style>
